<template>
  <div>
    <section class="section is-main-section">
      <card-component
          :title="
					$route.params.id ? `${form.name} Düzenle` : 'Site Oluştur'
				"
          icon="domain">
        <form @submit.prevent="save">
          <b-field grouped group-multiline>
            <b-field label="Name" expanded>
              <b-input
                  size="is-small"
                  v-model="form.name"
                  icon="domain"
                  placeholder="Name"
                  name="name"
                  required/>
            </b-field>
            <b-field label="Callback URL" expanded>
              <b-input
                  size="is-small"
                  v-model="form.callbackUrl"
                  icon="sitemap"
                  placeholder="Callback URL"
                  name="callbackUrl"
                  required/>
            </b-field>
            <!--
            <b-field label="KK Callback URL" expanded>
              <b-input size="is-small" v-model="form.creditCardCallback" icon="sitemap" placeholder="Callback URL"
                       name="callbackUrl"/>
            </b-field>
            -->
          </b-field>
          <hr/>

          <b-field group-multiline grouped>
            <b-field label="Çekim Komisyonu" expanded>
              <b-input
                  size="is-small"
                  v-model="form.withdrawRate"
                  required/>
            </b-field>
            <b-field label="Bakiye Limiti" expanded>
              <b-input
                  size="is-small"
                  v-model="form.balanceLimit"
                  required/>
            </b-field>
          </b-field>
          <hr/>
          <template v-for="(item, key) in form.depositSettings">
            <b-field group-multiline grouped :id="`commissionKey-${key}`">
              <b-field
                  :label="key + ' Yatırım Komisyonu'"
                  expanded>
                <b-input
                    size="is-small"
                    v-model="item.depositRate"/>
              </b-field>
              <b-field :label="key + ' Chat Id'" expanded>
                <b-input
                    size="is-small"
                    v-model="item.chatId"/>
              </b-field>
            </b-field>
            <hr/>
          </template>
          <b-field label="Durum">
            <b-switch size="is-small" v-model="form.status">{{
                form.status ? "Aktif" : "Pasif"
              }}
            </b-switch>
          </b-field>
          <hr/>
          <div class="is-flex is-justify-content-end is-flex-grow-1">
            <div class="control mr-2">
              <b-button
                  native-type="submit"
                  type="is-primary"
                  size="is-small">
                {{ $route.params.id ? "Güncelle" : "Ekle" }}
              </b-button>
            </div>
            <div class="control">
              <b-button
                  type="is-primary is-outlined"
                  size="is-small"
                  @click="$router.go(-1)"
              >Geri Dön
              </b-button
              >
            </div>
          </div>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import mapValues from "lodash/mapValues"
import CardComponent from "@/components/CardComponent"
import {SiteService as Service} from "@/services"

export default {
  name: "SiteCreateOrUpdate",
  components: {
    CardComponent,
  },
  data() {
    return {
      allSelect: false,
      isLoading: false,
      form: {
        name: null,
        callbackUrl: null,
        withdrawRate: 0,
        depositSettings: {},
        status: true,
        balanceLimit: 0
      },
    }
  },
  mounted() {
    this.depositTypes.map((item) => {
      this.form.depositSettings[item] = {
        depositRate: 0,
        chatId: null,
      }
    })
    if (this.$route.params.id) this.getData()
  },
  methods: {
    getData() {
      Service.edit(this.$route.params.id).then((res) => {
        this.form = {
          uuid: res.data.uuid,
          name: res.data.name,
          balanceLimit: res.data.balanceLimit,
          callbackUrl: res.data.callbackUrl,
          withdrawRate: res.data.withdrawRate,
          status: res.data.status,
          depositSettings: res.data.depositSettings,
        }

        this.depositTypes.map((item) => {
          if (!this.form.depositSettings[item]) {
            this.form.depositSettings[item] = {
              depositRate: 0,
              chatId: null,
            }
          }
        })
        Object.keys(this.form.depositSettings).forEach((item) => {
          if (this.depositTypes.indexOf(item) === -1)
            delete this.form.depositSettings[item]
        })
      })
    },
    save() {
      Service[this.$route.params.id ? "update" : "create"](this.form)
          .then(() => {
            this.$buefy.toast.open({
              message: this.$route.params.id ? "Güncellendi" : "Eklendi", type: "is-success",
            })
            this.reset()
            this.$router.go(-1)
          })
          .catch((err) =>
              this.$buefy.toast.open({
                message: err.message,
                position: "is-top-right",
                type: "is-danger",
              })
          )
    },
    reset() {
      this.form = mapValues(this.form, (item) => {
        if (item && typeof item === "object") {
          return []
        }
        return null
      })
    },
  },
}
</script>
